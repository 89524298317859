.parsley-errors-list {
  padding: 0;
  margin: .5rem 0;
  list-style-type: none; }
  .parsley-errors-list li {
    color: #ff7473; }
  .parsley-errors-list:not(.filled) {
    display: none; }

.form-section {
  display: none; }
  .form-section.current {
    display: block; }
